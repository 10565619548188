<template>
  <v-container fluid>
    <v-sheet>
      <v-row
        dense
        justify="end"
        class="pa-4"
      >
        <v-col cols="auto">
          <v-btn
            color="primary"
            small
            @click="updateInvoice"
          >
            {{$_strings.invoice.TITLE_EDIT}}
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-data-table
            :headers="headers"
            calculate-widths
            :items="itemsInvoice"
            item-key="noInvoice"
            :server-items-length="invoiceListTotalEntry"
            :options.sync="pagination"
            :footer-props="{showCurrentPage: true, showFirstLastPage: true, 'items-per-page-options': $_item_per_page}"
          >
            <template v-slot:[`item.shipmentCargoTitle`]=items>
              <span>{{ items.item.shipmentCargoTitle }}</span>
              <br/>
              <span class="size-12">{{ formatDate(items.item.shipmentDate) }}</span>
            </template>
            <template v-slot:[`item.shipmentTitle`]=items>
              <span>{{ items.item.shipmentTitle }} - {{ items.item.companyAbbreviation }}</span>
            </template>
            <template v-slot:[`item.transportTypeName`]=items>
              <span>{{ items.item.transportTypeName }}</span>
              <br/>
              <span class="size-12">{{ items.item.transportLicense }}</span>
            </template>
            <template v-slot:[`item.qty`]={item}>
              <v-text-field
                type="number"
                min="0"
                step="1"
                outlined
                dense
                class="caption input-qty"
                id="qty"
                :value="item.qty"
                @change="(e) => onChangeQty(e, item)"
              >
              </v-text-field>
            </template>
            <template v-slot:[`item.priceUnit`]={item,index}>
              <v-text-field
                min="0"
                step="1"
                outlined
                dense
                prefix="Rp."
                class="caption input-price"
                id="priceUnit"
                :value="formatAmount(item.priceUnit)"
                item-text="priceUnit"
                item-value="id"
                @input.native="changePrice($event, index)"
                @change="(e) => onChangePrice(e, item)"
              >
              </v-text-field>
            </template>
            <template v-slot:[`item.totalPrice`]=items>
              <span>Rp. {{ formatAmount(items.item.totalPrice) }}</span>
              <br/>
              <span class="size-12">{{ items.item.costName }}</span>
            </template>
            <template v-slot:[`footer.page-text`]="props">
              <span>
                {{$_strings.invoice.INVOICE_LIST}}
                <span v-if="itemsInvoice.length">
                  {{props.pageStart}}-{{props.pageStop}} of {{props.itemsLength}}
                </span>
              </span>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-sheet>
  </v-container>
</template>

<script>
import dayjs from 'dayjs';
import { thousandSeparated } from '../../helper/commonHelpers';

export default {
  name: 'invoice-edit',
  data() {
    return {
      itemsInvoice: [],
      valid: false,
      headers: [
        {
          text: this.$_strings.invoice.ORDER_NO,
          value: 'shipmentCargoTitle',
          class: 'white--text primary text-capitalize',
        },
        {
          text: this.$_strings.invoice.ORDER_NO_SHIPPER,
          value: 'shipmentTitle',
          class: 'white--text primary text-capitalize',
        },
        {
          text: this.$_strings.invoice.ADD_TRANSPORT,
          value: 'transportTypeName',
          class: 'white--text primary text-capitalize',
        },
        {
          text: this.$_strings.invoice.QUANTITY,
          value: 'qty',
          class: 'white--text primary text-capitalize',
        },
        {
          text: this.$_strings.invoice.PRICE_UNIT,
          value: 'priceUnit',
          class: 'white--text primary text-capitalize',
        },
        {
          text: this.$_strings.invoice.PRICE,
          value: 'totalPrice',
          class: 'white--text primary text-capitalize',
        },
      ],
      invoiceListTotalEntry: 0,
      totalItems: 0,
      shipmentList: [
        {
          id: null,
          shipmentId: null,
          shipmentTitle: null,
        },
      ],
      form: [],
      pagination: {
        itemsPerPage: 10,
        page: 1,
        sortBy: [],
        sortDesc: [],
      },
    };
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.xs;
    },
    myUserInfo() {
      return this.$store.state.user.myUserInfo;
    },
  },
  watch: {
    pagination: {
      handler() {
        this.fetchInvoiceDetail();
      },
      deep: true,
    },
  },
  methods: {
    refactorForm(event) {
      const priceUnit = event.replace(/[^,\d]/g, '');
      return priceUnit;
    },
    changePrice(event, index) {
      const isNumberOnly = /^\d+$/.test(event.data);
      const tempValue = this.itemsInvoice[index].priceUnit;
      if (isNumberOnly) {
        const priceUnit = `${this.itemsInvoice[index].priceUnit}${event.data}`;
        this.itemsInvoice[index].priceUnit = Number(priceUnit);
      } else if (event.data) {
        event.target.value = Intl.NumberFormat('en-US').format(tempValue);
        this.itemsInvoice[index].priceUnit = tempValue;
      } else {
        this.itemsInvoice[index].priceUnit = event.target.value.replace(/./g, '');
      }
    },
    onChangeQty(event, item) {
      const index = this.form.findIndex((e) => e.trxDetailId === item.trxDetailId);
      this.form[index].qty = event;
      this.itemsInvoice[index].totalPrice = event * item.priceUnit;
      this.form[index].totalPrice = event * this.form[index].priceUnit;
    },
    onChangePrice(event, item) {
      const index = this.form.findIndex((e) => e.trxDetailId === item.trxDetailId);
      this.form[index].priceUnit = this.refactorForm(event);
      this.itemsInvoice[index].totalPrice = this.refactorForm(event) * this.form[index].qty;
      this.form[index].totalPrice = this.refactorForm(event) * this.form[index].qty;
    },
    formatDate(date) {
      return dayjs(date).format('DD MMM YYYY');
    },
    formatAmount(amount) {
      return thousandSeparated(amount);
    },
    fetchInvoiceDetail() {
      this.$root.$loading.show();
      this.$_services.invoice.invoiceDetail(this.$route.params.invoiceId)
        .then((result) => {
          this.itemsInvoice = result.data;
          this.form = this.itemsInvoice.map((res) => ({
            ...res,
          }));
          this.invoiceListTotalEntry = result.data.length;
        }).finally((e) => {
          this.$root.$loading.hide();
        });
    },
    updateInvoice() {
      this.$root.$loading.show();
      this.$_services.invoice.updateInvoice(this.form)
        .then((res) => {
          this.$dialog.notify.success(this.$_strings.invoice.EDIT_MESSAGE_TEXT);
          this.dialog = false;
          this.$router.push('/main/invoice-transporter/list');
        })
        .catch((err) => {
          if (err.data && err.data.message) {
            this.dialog = false;
            this.$dialog.notify.error(err.data.message);
          }
        })
        .finally(() => {
          this.$root.$loading.hide();
        });
    },
  },
};
</script>

<style lang="scss" scoped>
  .v-chip.v-size--default {
      font-size: 12px;
      margin-top: 3px;
  }
  .order-checked{
    font-size: 14px;
    color: #4d4f5c;
  }
  .size-12 {
    font-size: 12px;
  }
  .input-price{
    top: 15px;
    width: 150px;
    .v-text-field--outlined.v-input--is-focused fieldset, .v-text-field--outlined.v-input--has-state fieldset {
      border: none;
    }
  }
  .input-qty{
    top: 15px;
    width: 50px;
    .v-text-field--outlined.v-input--is-focused fieldset, .v-text-field--outlined.v-input--has-state fieldset {
      border: none;
    }
  }
  /* Chrome, Safari, Edge, Opera */
  .input-price ::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
  .input-qty ::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
  input[type="number"] {
      -moz-appearance: textfield;
  }
</style>
